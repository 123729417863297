import Link from "next/link";
import styles from "../../styles/NavBar.module.css";
import { routes } from "../../config/routes";

export const NavBar = () => (
  <nav className={styles.nav}>
    {routes.map(({ href, title }, index) => {
      return (
        <div className={styles.navItem} key={index}>
          <Link href={href}>
            <a className={styles.navItemText}>{title}</a>
          </Link>
        </div>
      );
    })}
  </nav>
);
