import { DefaultSeo } from "next-seo";
import { useRouter } from "next/router";

const BASE_URL = "https://www.alexbarba.dev";

export const DefaultSeoComponent = () => {
  const { asPath } = useRouter();

  return (
    <DefaultSeo
      titleTemplate="Àlex Barba | %s"
      defaultTitle="Àlex Barba"
      description="Àlex Barba personal site | Software Engineer"
      canonical={`${BASE_URL}${asPath}`}
      additionalMetaTags={[
        {
          property: "dc:creator",
          content: "Àlex Barba",
        },
        {
          name: "application-name",
          content: "Àlex Barba personal site",
        },
      ]}
      additionalLinkTags={[
        {
          rel: "icon",
          href: "/favicon-16x16.png",
          sizes: "16x16",
          type: "image/png",
        },
        {
          rel: "icon",
          href: "/favicon-32x32.png",
          sizes: "32x32",
          type: "image/png",
        },
        {
          rel: "icon",
          href: "/android-chrome-192x192.png",
          sizes: "192x192",
          type: "image/png",
        },
        {
          rel: "icon",
          href: "/android-chrome-512x512.png",
          sizes: "512x512",
          type: "image/png",
        },
        {
          rel: "apple-touch-icon",
          href: "/apple-touch-icon.png",
          sizes: "180x180",
          type: "image/png",
        },
      ]}
      openGraph={{
        type: "website",
        locale: "en",
        url: `${BASE_URL}${asPath}`,
        site_name: "Àlex Barba",
        title: "Àlex Barba",
        description: "Àlex Barba personal site | Software Engineer",
      }}
    />
  );
};
