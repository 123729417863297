import { useRouter } from "next/router";
import { NavBar } from "../navbar";
import { routes } from "../../config/routes";
import Image from "next/image";

export const SectionHeader = () => {
  const { pathname } = useRouter();

  const {
    title,
    image: { url, altText },
  } = routes.find(({ href }) => href === pathname) || {
    title: "",
    image: { url: "", altText: "" },
  };

  return (
    <>
      <NavBar />
      <div className="sectionTitle">
        <h2>{title}</h2>
        <div className="image">
          <Image
            src={url}
            alt={altText}
            priority
            layout="fill"
            objectFit="contain"
            objectPosition="center"
          />
        </div>
      </div>
    </>
  );
};
