export const routes = [
  {
    href: "/",
    title: "Homepage",
    image: {
      url: "",
      altText: "",
    },
  },
  {
    href: "/my-beginnings/index.html",
    title: "My beginnings",
    image: {
      url: "/images/me.png",
      altText: "Me",
    },
  },
  {
    href: "/my-projects/index.html",
    title: "My projects",
    image: {
      url: "/images/work.gif",
      altText: "Developer working",
    },
  },
  {
    href: "/my-actual-job/index.html",
    title: " My actual job",
    image: {
      url: "/images/money.gif",
      altText: "Money bag",
    },
  },
  {
    href: "/my-job-history/index.html",
    title: "My job history",
    image: {
      url: "/images/moneyburning.gif",
      altText: "Money burning",
    },
  },
  {
    href: "/contact-me/index.html",
    title: " Contact me!",
    image: {
      url: "/images/emailme.gif",
      altText: "Email",
    },
  },
  {
    href: "/inspiring-websites/index.html",
    title: "Websites that inspired me",
    image: {
      url: "/images/spinningearth.gif",
      altText: "Spinning earth",
    },
  },
];
