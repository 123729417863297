import { FC } from "react";
import { useEffect, useState } from "react";
import Image from "next/image";
import styles from "../../styles/Layout.module.css";
import { useRouter } from "next/router";
import { SectionHeader } from "../../components/sectionHeader";
import { routes } from "../../config/routes";
import Marquee from "react-fast-marquee";
import { SeoComponent } from "../seo-component";
import Link from "next/link";

export const Layout: FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const { pathname } = useRouter();

  useEffect(() => {
    const body = document.querySelector("body");
    if (pathname != "/") {
      document.body.classList.add("page");
    } else {
      body?.classList.remove("page");
    }

    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, Math.floor(Math.random() * (500 - 200 + 1) + 300));
  }, [pathname]);

  const { title } = routes.find(({ href }) => href === pathname) || {
    title: "",
    image: { url: "", altText: "" },
  };

  return (
    <>
      <SeoComponent title={title} />
      <header className={styles.headerWrapper}>
        <div className={styles.header}>
          <h1 className={styles.headerTitle}>Hi, this is Àlex Barba!</h1>
          <div className={styles.headerImage}>
            <Image
              src="/images/welcometomyworld.gif"
              alt="Welcome to my world"
              priority
              layout="fill"
              objectFit="contain"
              objectPosition="center"
            />
          </div>
        </div>
      </header>
      <main className={styles.main}>
        {loading ? (
          <div className={styles.loading}>
            <Image
              src="/images/loading.gif"
              alt="Loading"
              layout="fill"
              objectFit="contain"
              objectPosition="center"
            />
          </div>
        ) : pathname != "/" ? (
          <>
            <SectionHeader /> {children}
          </>
        ) : (
          <>
            <div className={styles.marqueeWrapper}>
              <Marquee gradient={false} className={styles.marquee}>
                <p className={styles.marqueeText}>
                  Any suggestion? Feedback is welcomed and appreciated! Feel
                  free to email me:{" "}
                  <Link
                    href="mailto:hello@alexbarba.dev?subject=Hi Àlex Barba!"
                    passHref={true}
                  >
                    <a
                      className="bold"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      hello@alexbarba.dev
                    </a>
                  </Link>
                  . Thanks for your collaboration!
                </p>
              </Marquee>
            </div>
            {children}
          </>
        )}
      </main>
      <footer>
        <hr />
        <div className={styles.footer}>
          <p>Copyright © {new Date().getFullYear()} Àlex Barba.</p>
          <p>ALL RIGHTS RESERVED. Last Updated: {new Date().getFullYear()}</p>
        </div>
      </footer>
    </>
  );
};
